import { Fragment, useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { EffectFade, Thumbs } from 'swiper';
import { Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setProducts, setProductsFull } from "../../store/slices/product-slice";
import { setModalidadSeleccionada } from "../../store/slices/modalidad-slice";
import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { getMedicamentos } from "../../firebase/firebase-methods";
import arrModalidad from "../../store/dataModalidad";

function ModalidadModal({ showModalidad, onHideModalidad }) {
    const dispatch = useDispatch();
    //const { arrModalidad } = useSelector((state) => state.modalidad);

 
 
    const { modalidadSeleccionada } = useSelector((state) => state.modalidad);
    //console.log("se obtiene arreglo modalidad");
    //console.log([arrModalidad])
    const onCloseModal = () => {
        onHideModalidad()
    }

    const handleSeleccionaModalidad = (modalidad) => {
        if (modalidad.id === modalidadSeleccionada.id) {
            cogoToast.info("La modalidad " + modalidad.nombre + " ya está seleccionada", { position: "bottom-left" })
        } else {
            dispatch(setModalidadSeleccionada(modalidad))
            //onCloseModal()
        }

    }
    const [modalidadSelec, setModalidadSelec] = useState(modalidadSeleccionada)
    useEffect(() => {
        setModalidadSelec(modalidadSeleccionada)
        const getProducts = async () => {
            const response = await getMedicamentos(modalidadSeleccionada.presku)
            dispatch(setProducts(response));
            dispatch(setProductsFull(response))
        }
        getProducts()
   
        return;
    }, [modalidadSeleccionada])

    return (
        <Modal show={showModalidad}
            onHide={onCloseModal}
            className="modal-modalidad"
        >
            <Modal.Header closeButton><h5>Seleccione modalidad de compra</h5></Modal.Header>
            <Modal.Body className="p-20">
                <div className="">


                    <table>

                        <tbody>
                            {arrModalidad.map((modalidad) => (
                                <tr key={modalidad.id}>
                                    <td className=" py-3">
                                        <p className=" titulo">{modalidad.nombre}</p>
                                        <p><span>{modalidad.tipo==="despacho" ? "Se despacha desde: " : "Se retira en: "}</span></p>
                                        <p>{modalidad.direccion}</p>

                                        <p><span>{"Horario: "}</span></p>
                                        <p>{modalidad.horario}</p>
                                        <p><span>{"Teléfono: "}</span></p>
                                        <p>{modalidad.telefono}</p>
                                    </td>
                                    <td className=" py-3">


                                        <button className={modalidad.id === modalidadSeleccionada.id ? "check-modalidad-true" : "check-modalidad-false"}
                                            onClick={() => handleSeleccionaModalidad(modalidad)}
                                            style={modalidad.id === modalidadSeleccionada.id ? { textAlign: "right", color: "#fff", backgroundColor: "#e58f2f" } : { textAlign: "left", color: "darkgray", backgroundColor: "#fff" }}
                                        ><FontAwesomeIcon icon={faCircle} /></button>




                                    </td>
                                </tr>


                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>


        </Modal>
    )

}

ModalidadModal.propTypes = {

    showModalidad: PropTypes.bool,
    onHideModalidad: PropTypes.func,


}

export default ModalidadModal;