import { functions, httpsCall } from "./firebase-config";

export const enviaMailSendgrid = async (objPedido) => {
  //console.log("recibe")
  //console.log(objPedido)
  let data = {
    correo: objPedido.email,
    nombre_pac:
      objPedido.datos_facturacion.nombre +
      " " +
      objPedido.datos_facturacion.apellidos,
    templateID: process.env.REACT_APP_TEMPLATE,
  };

  let respuesta = { obtenido: false };
  try {
    const correoSendgrid = httpsCall(functions, "SendMailGridVO2");
    const responseCorreo = await correoSendgrid(data);
    //console.log(responseCorreo)
    respuesta = { obtenido: true, data: responseCorreo.data };
    return respuesta;
  } catch (error) {
    console.error(error);
    respuesta.error = error;
    return respuesta;
  }
};

export const creaPagoTbk = async (order) => {
  //console.log("recibe")
  //console.log(order)
  let respuesta = { obtenido: false };
  try {
    const pagoTransbank = httpsCall(functions, "CreaPagoTbk2");
    const responseTbk = await pagoTransbank(order);

    respuesta = { obtenido: true, data: responseTbk.data };
    return respuesta;
  } catch (error) {
    console.error(error);
    respuesta.error = error;
    return respuesta;
  }
};

export const getNumeroPedido = async () => {
  let idPedido = { obtenido: false };
  //console.log("se ejecuta get numero de pedido")
  try {
    const numPedido = httpsCall(functions, "GetIdPedido");
    const response = await numPedido();
    idPedido.id = response.data;
    idPedido.obtenido = true;
    return idPedido;
  } catch (error) {
    console.error("error");
    console.error(error);
    idPedido.error = error;
    return idPedido;
  }
};

export const getEstadoBeeTrack = async (idPedido) => {
  //console.log("se ejecuta getEstadoBeeTrack" + idPedido)
  let estado = { recibido: false };
  try {
    const getEstado = httpsCall(functions, "EstadoBeexPedidoFoto");
    const response = await getEstado(idPedido);
    //console.log(response.data.response);
    estado = response.data.response;
    estado.recibido = true;
  } catch (error) {
    //console.log(error);
  }
  return estado;
};

export const getDescuentoPaciente = async (obj) => {
  //console.log("se ejecuta getDescuentoPaciente")
  //console.log(obj)
  let descuento = {};
  try {
    const getDescuento = httpsCall(functions, "Valor_Venta_Paciente2");
    const response = await getDescuento(obj);
    //console.log(response);
    let descuentoPac = response.data.GRAT;
    let stockMed = response.data.STOCK;
    descuento = { descuentoPac, stockMed };
  } catch (error) {
    //console.log(error);
  }
  return descuento;
};

export const getRecetaRNC = async (rutp) => {
  //console.log("se ejecuta getRecetaRNC")
  //console.log(rutp)
  try {
    const getReceta = httpsCall(functions, "Get_RNC_Pac");
    const response = await getReceta(rutp);
    //console.log(response);
    return response.data;
  } catch (error) {
    //console.log(error)
  }
  return [];
};

export const getRecetaRCC = async (rutp) => {
  //console.log("se ejecuta getRecetaRNC")
  //console.log(rutp)
  try {
    const getReceta = httpsCall(functions, "Get_RCC_Pac");
    const response = await getReceta(rutp);
    //console.log(response);
    return response.data;
  } catch (error) {
    //console.log(error)
  }
  return [];
};

export const getPedidoTracking = async (idPedido, email) => {
  let result = [];
  try {
    const getPedido = httpsCall(functions, "buscarPedidoPorNumeroVO2");
    const response = await getPedido({ numeroPedido: idPedido, email: email });
    //console.log(response);
    result = response.data;
  } catch (error) {
    //console.log(error);
  }
  return result;
};

export const SendGridAdjMulti = async (data) => {
  let response = { status: "no enviado" };
  try {
    const sendMail = httpsCall(functions, "SendGridAdjMulti");
    const result = await sendMail(data);
    response = result;
    // Verificar si la llamada fue exitosa
    if (response.data.messaje === "ok") {
      //console.log('Correo enviado exitosamente');
    } else {
      console.error("Error al enviar el correo:");
    }
  } catch (error) {
    console.error("Error inesperado:", error);
  }
  return response;
};

export const getDatosCoti = async (idPaciente, idCotizacion) => {
  let result;
  try {
    const getCoti = httpsCall(functions, "GetCotizacion_v2");
    const response = await getCoti({
      uidHumano: idPaciente,
      idDocumento: idCotizacion,
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI
    });
    result = response.data;
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const subeArchivoCotizacion = async (uidPac, idCotizacion, file) => {
  try {
    const base64Data = await readFileAsDataURL(file);
    let data = {
      uid: uidPac,
      idCotizacion: idCotizacion,
      file: base64Data.split(",")[1],
      fileName: file.name,
      type: file.type,
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI
    };
    const subeArchivoCoti = httpsCall(functions, "SubeArchivoCotizacion");
    const response = await subeArchivoCoti(data);

    return response.data;
  } catch (error) {
    console.log("Error al subir archivo");
    console.log(error);
    return null;
  }
};

const readFileAsDataURL = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject("Error al leer el archivo: " + error);
    };
  });
};
export const UpdateCotizacion = async (uidPac, idCotizacion, doc, viene) => {
 
  try {
  let data = {}
 
    if(viene === "Receta"){
      data = {
        uid: uidPac,
        idCotizacion: idCotizacion,
        documentos: doc,
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI,
        evento: "Paciente sube receta",
        estadoEvento: "ENVIADA",
        notaEvento: "Paciente ha subido una receta para validar"
      };
    }else if (viene === "Receta nueva"){
      data = {
        uid: uidPac,
        idCotizacion: idCotizacion,
        documentos: doc,
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI,
        evento: "Paciente sube nueva receta",
        estadoEvento: "ENVIADA",
        notaEvento: "Paciente ha subido una nueva receta para validar"
      };
    }else{
      data = {
        uid: uidPac,
        idCotizacion: idCotizacion,
        documentos: doc,
        apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI,
        evento: "Paciente sube comprobante",
        estadoEvento: "ENVIADA",
        notaEvento: "Paciente ha subido un comprobante de transferencia"
      };
    }
 
    const actualizaDocCoti = httpsCall(functions, "UpdateCotizacion");
    const response = await actualizaDocCoti(data);
    // console.log(response);
    return response;
  } catch (error) {
    console.log("Error al actualizar documentos");
    console.log(error);
    return {
      actualizado: false,
      message: error,
    };
  }
};
export const UpdateCotiPac = async (uidPac, devolucion) => {
  try {
    let data = {
      uid: uidPac,
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI,
      devolucion: devolucion,
    };
    const actualizaDatosDev = httpsCall(functions, "UpdateCotiPac");
    const response = await actualizaDatosDev(data);
    // console.log(response);
    return response;
  } catch (error) {
    console.log("Error al actualizar datos devolucion");
    console.log(error);
    return {
      actualizado: false,
      message: error,
    };
  }
};
export const CrearPagoCoti = async (uidPac, monto, idCoti) => {
  let returnUrl =
    process.env.REACT_APP_URL_RETORNO_COTI + "idcot=" +
    idCoti +
    "&uuid=" +
    uidPac +
    "&amb=https://ligafarmacia.cl";

  try {
    let data = {
      buy_order: idCoti,
      session_id: uidPac,
      amount: monto,
      return_url: returnUrl,
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY_COTI
    };
    const crearPago = httpsCall(functions, "CreaPagoTbkCoti2");
    const response = await crearPago(data);
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error al crear url transbank");
    console.log(error);
    return {
      actualizado: false,
      message: error,
    };
  }
};
// export const getEstadoBeeTrack = async (idPedido) => {
// console.log("se ejecuta getEstadoBeeTrack" + idPedido)
//     let estado = {};
//     try {
//         const getEstado = functions.httpsCallable('EstadoBeexPedido')
//         const response = await getEstado(idPedido);
//         //console.log(response);
//         estado = response.data;
//     } catch (error) {
//         //console.log(error);
//     }
//     return estado;

// }
