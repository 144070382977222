import PropTypes from "prop-types";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PacienteModal from "../../pages/other/ModalPaciente"
import ModalidadModal from "../../components/modalidad/modalidadModal";
import { addPaciente, deletePaciente, setPacienteSeleccionado } from "../../store/slices/paciente-slice";
import { setModalidadSeleccionada } from "../../store/slices/modalidad-slice";
import calcularDigitoVerificador from "../../helpers/rut";
import { useRut, isValid, unformat, format, } from 'react-rut';
import { Button } from "react-bootstrap";

const BreadcrumbWrap = ({ pages }) => {
  const [modalPacienteShow, setModalPacienteShow] = useState(false);
  const [modalModalidadShow, setModalModalidadShow] = useState(false)
  const [tableShow, setTableShow] = useState(false);
  const { arrPacientes } = useSelector((state) => state.paciente);
  const { pacienteSeleccionado } = useSelector((state) => state.paciente)
  const { arrModalidad } = useSelector((state) => state.modalidad)
  const { modalidadSeleccionada } = useSelector((state) => state.modalidad)
  const dispatch = useDispatch();
  return (
    <div className="breadcrumb-area pt-35 pb-35">
      <div className="container">
        <div className="row">

          <div className="area-left col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12">
            <div>
              <Breadcrumb className="area-left">
                {pages?.map(({ path, label }, i) => i !== pages.length - 1 ? (
                  <Breadcrumb.Item key={label} linkProps={{ to: path }} linkAs={Link}>
                    {label}
                  </Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item key={label} active>
                    {label}
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
              <div id="modalidad">
                <p className="modalidad">Selecciona ubicación y modalidad de compra:
                <span className="seleccionada"><strong>{modalidadSeleccionada.nombre}</strong></span>
                </p>

              </div>

            </div>
          </div>
          <div 
          className="area-right text-start align-items-center justify-content-center col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12"
          style={{alignContent: "center"}}
          >
            <button className="boton"
            onClick={() => setModalModalidadShow(true)}
            >Ubicación</button>
          </div>
        </div>

       
        <PacienteModal
          showPacienteModal={modalPacienteShow}
          onHidePacienteModal={() => setModalPacienteShow(false)}
        />
        <ModalidadModal
          showModalidad={modalModalidadShow}
          onHideModalidad={() => setModalModalidadShow(false)}
        />
      </div>
    </div>
  );
};

BreadcrumbWrap.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired
  })).isRequired
}

export default BreadcrumbWrap;
